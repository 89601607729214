import { LoadingButton } from '@mui/lab';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  TextField
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { getLoginEndpoint, login } from '../../../api/AuthenticationAPI';
import { setLocalSession } from '../../../api/setupAxios';
import Iconify from '../../../components/Iconify';
import UserContext from '../../../contexts/UserContext';

export default function LoginForm({ animateControls }) {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);
  const [ibmLogin, setIbmLogin] = useState(false);

  const { t } = useTranslation();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('La dirección de email debe de ser válida')
      .required('Es necesario introducir un email'),
    password: ibmLogin
      ? Yup.string()
      : Yup.string().required('Es necesario introducir una contraseña')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      animateControls.start({
        scale: [1, 0.9, 1.1, 1],
        transition: { duration: 4, yoyo: Infinity }
      });

      if (ibmLogin) {
        getLoginEndpoint().then((res) => {
          if (res.data) {
            const { url, codeChallenge } = res.data;
            window.location.replace(url);
            localStorage.setItem('codeChallenge', codeChallenge);
          } else setSubmitting(false);
        });
      } else
        login(values.email, values.password).then((res) => {
          if (res.data) {
            setLocalSession(res.data.accessToken);
            userContext.getUserData().then((res) => {
              if (res.data) {
                const originLink = localStorage.getItem('originLink');
                if (originLink) navigate(originLink, { replace: true });
                else navigate('/', { replace: true });
              }
            });
          } else {
            animateControls.stop();

            setErrors({
              email: true,
              password: 'Dirección de correo electrónico o contraseña no válidos'
            });
            setSubmitting(false);
          }
        });
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} mb={5}>
          <TextField
            color="primary"
            fullWidth
            autoComplete="email"
            type="email"
            label={t('email')}
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            onChange={(ev) => {
              getFieldProps('email').onChange(ev);
              const mail = ev.target.value
              const re = /^.*@.*ibm\.com$/

              if (mail.match(re))setIbmLogin(true);
              else setIbmLogin(false);
            }}
          />

          {!ibmLogin && (
            <>
              <TextField
                fullWidth
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                label={t('password')}
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />

              <FormControlLabel
                control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
                label={t('remember-user')}
              />
            </>
          )}
        </Stack>

        {!ibmLogin ? (
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disableElevation
          >
            {t('login')}
          </LoadingButton>
        ) : (
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disableElevation
          >
            {t('login-w3')}
          </LoadingButton>
        )}
      </Form>
    </FormikProvider>
  );
}
